import { ANALYTICS_ROLE, SUPERUSER } from "../../constants";
import {
  COACHES_CORNER_ROUTE,
  IDP_ROUTE,
  SKILLCORNER_ROUTE,
} from "../../routes";
import { Divider, Stack } from "@mui/material";

import { Folder } from "../../api/types";
import NavFooter from "../NavFooter";
import ScoutingMenu from "./ScoutingMenu";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import TreeItemLink from "../TreeItemLink";
import { useOktaAuth } from "@okta/okta-react";
import { useUserContext } from "../../contexts/UserContext";

export default function NavMenu(props: { folders: Folder[] | null }) {
  // HOOKS
  const { authState } = useOktaAuth();
  const { roles } = useUserContext();

  // CONTEXT
  const { homePagePath } = useUserContext();

  return (
    <>
      {authState?.isAuthenticated && (
        <Stack
          height="100%"
          direction="column"
          display="flex"
          justifyContent="space-between"
          sx={{ paddingLeft: 1, paddingRight: 1 }}
        >
          <SimpleTreeView defaultExpandedItems={["scouting"]}>
            {/* HOME */}
            <>
              <TreeItemLink label="HOME" to={homePagePath} />

              <Divider />
            </>

            {/* COACHES CORNER */}
            <TreeItemLink label="COACHCORNER" to={COACHES_CORNER_ROUTE} />

            {/* IDP */}
            <TreeItemLink label="IDP" to={IDP_ROUTE} />

            {/* SKILL CORNER */}
            <TreeItemLink label="SKILLCORNER" to={SKILLCORNER_ROUTE} />

            {/* SCOUTING */}
            {(roles.includes(SUPERUSER) || roles.includes(ANALYTICS_ROLE)) && (
              <ScoutingMenu folders={props.folders} />
            )}
          </SimpleTreeView>

          <NavFooter />
        </Stack>
      )}
    </>
  );
}
