// Tokens seems to have a 1 hour expiration
// Since access (and refresh) tokens expire in 1 hour from the time we get one
// we should check to see if the current epoch (plus some arbitrary buffer) is
// greater than or equal to the expiration time; this in turn means that there should
// be a forty five minute window in which we have the opportunity in the front end to
// refresh the access token

import { AuthState } from "@okta/okta-auth-js";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";

const bufferValue = 30 * 60; // thirty minutes

export function isTokenExpired(authState: AuthState) {
  const isExpired =
    !authState?.accessToken?.expiresAt ||
    authState?.accessToken?.expiresAt * 1000 - bufferValue >= Date.now();

  console.debug(
    `expired: ${isExpired} | expiresAt: ${authState?.accessToken?.expiresAt}`
  );

  return isExpired;
}

export async function checkRefreshToken(
  oktaContext: IOktaContext
): Promise<boolean> {
  console.debug("checking refresh token");

  if (oktaContext.authState && isTokenExpired(oktaContext.authState)) {
    console.debug("refreshing access token");
    const renewToken = await oktaContext.oktaAuth.token.renewTokens();
    oktaContext.oktaAuth.tokenManager.setTokens(renewToken);
    return true;
  }

  return false;
}
