import {
  AUTH,
  CONSTANTS,
  DEPTH_CHARTS,
  FOLDERS,
  LISTS,
  MATCH_REPORTS,
  NOTES,
  NOTIFICATIONS,
  OPERATIONS,
  PLAYERS,
  PLAYER_ATTRIBUTES,
  SCOUTED_PLAYERS,
  SCOUTING,
  SCOUTING_REPORTS,
  SHARES,
  USERS,
} from "./endpoints";
import {
  Auth,
  DepthChart,
  DepthChartPlayer,
  DepthChartPlayerCreate,
  DepthChartPositionList,
  Folder,
  League,
  Match,
  MatchReport,
  Note,
  OperationsData,
  Page,
  Player,
  PlayerAttributes,
  PlayerAttributesCreate,
  PlayerCreate,
  PlayerDatabaseMeta,
  PlayerKPIs,
  PlayerList,
  PlayerRating,
  PlayerUpdate,
  ReportQuality,
  ScoutedPlayer,
  ScoutedPlayerCreate,
  ScoutingReport,
  Share,
  TableEntries,
  User,
  WeeklyStat,
} from "./types";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-premium";

import { Notification } from "./types";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// console.debug(axios.defaults.baseURL);

class APIClient {
  toSnakeCase(s: string) {
    return s.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
  }

  arrToSnake<T extends object>(arr: T[]) {
    return arr.map((t) => this.obToSnake(t));
  }

  obToSnake<T extends object | null | undefined>(ob: T) {
    if (ob == null || ob === undefined) {
      return;
    }

    const snakened: any = {};

    for (const [k, v] of Object.entries(ob)) {
      //   console.debug(`${k}: ${v} : ${typeof v} : ${Array.isArray(v)}`);

      if (Array.isArray(v)) {
        const key = this.toSnakeCase(k);
        const value = typeof v == "object" ? v : this.arrToSnake(v);
        snakened[key] = value;
      } else if (typeof v == "object") {
        const key = this.toSnakeCase(k);
        const value = this.obToSnake(v);
        snakened[key] = value;
      } else {
        const value = this.toSnakeCase(k);
        snakened[value] = v;
      }
    }

    return snakened;
  }

  normalizePlayers(players: Player[]) {
    // console.debug(players);
    const trimmedPlayers: {
      id: number;
      competition_id: number;
      season_id: number;
    }[] = [];

    for (const player of players) {
      trimmedPlayers.push({
        id: player.id,
        competition_id: player.competition_id,
        season_id: player.season_id,
      });
    }

    return trimmedPlayers;
  }

  async createDepthChart(depthChart: DepthChart) {
    const response = await axios.post<DepthChart>(DEPTH_CHARTS, depthChart);
    return response.data;
  }

  async createDepthChartPlayer(depthChartPlayer: DepthChartPlayerCreate) {
    const response = await axios.post<DepthChartPlayerCreate>(
      `${DEPTH_CHARTS}players`,
      {
        ...depthChartPlayer,
      }
    );
    return response.data;
  }

  async createFolder(folder: Folder) {
    const response = await axios.post<Folder>(FOLDERS, { ...folder });
    return response.data;
  }

  async createList(owner: string, name: string, players: Player[]) {
    const response = await axios.post<PlayerList>(LISTS, {
      owner: owner,
      name: name,
      players: this.normalizePlayers(players),
    });
    return response.data;
  }

  async createMatchReport(report: MatchReport) {
    const response = await axios.post<MatchReport>(`${PLAYERS}match_reports/`, {
      ...report,
    });
    return response.data;
  }

  async createNote(content: string, player_id: number, created_by: string) {
    const response = await axios.post<Note>(NOTES, {
      content: content,
      player_id: player_id,
      created_by: created_by,
      updated_by: created_by,
    });
    return response.data;
  }

  async createPlayer(player: PlayerCreate) {
    const response = await axios.post<Player[]>(PLAYERS, {
      ...player,
    });
    return response.data;
  }

  async createPlayerAttributes(playerAttributes: PlayerAttributesCreate) {
    const response = await axios.post<PlayerAttributes>(
      `${PLAYER_ATTRIBUTES}${playerAttributes.player_id}`,
      {
        ...playerAttributes,
      }
    );
    return response.data;
  }

  async createScoutedPlayer(scoutedPlayer: ScoutedPlayerCreate) {
    const response = await axios.post<ScoutedPlayer>(`${SCOUTED_PLAYERS}`, {
      ...scoutedPlayer,
    });
    return response.data;
  }

  async createScoutingReport(report: ScoutingReport) {
    const response = await axios.post<ScoutingReport>("/scouting/reports", {
      ...report,
    });
    return response.data;
  }

  async deleteDepthChart(id: number) {
    const response = await axios.delete(`${DEPTH_CHARTS}${id}`);
    return response.data;
  }

  async deleteDepthChartPlayer(depthChartPlayerId: number) {
    const response = await axios.delete(
      `${DEPTH_CHARTS}players/${depthChartPlayerId}`
    );

    return response.data;
  }

  async deleteFolder(id: number, owner: string) {
    const response = await axios.delete(`${FOLDERS}${id}`, {
      params: {
        owner: owner,
      },
    });
    return response.data;
  }

  async deleteList(id: number, owner: string) {
    const response = await axios.delete(`${LISTS}${id}`, {
      params: {
        owner: owner,
      },
    });
    return response.data;
  }

  async deleteNote(id: number) {
    const response = await axios.delete(`${NOTES}${id}`);
    return response.data;
  }

  async deletePlayer(player: Player) {
    const response = await axios.delete<Player>(`${PLAYERS}${player.id}`);
    return response.data;
  }

  async deleteScoutingReport(id: number) {
    const response = await axios.delete(`${SCOUTING_REPORTS}${id}`);
    return response.data;
  }

  async getAuth(user: string): Promise<Auth> {
    const response = await axios.get<Auth>(AUTH, {
      params: {
        user: user,
      },
    });
    return response.data;
  }

  async getConstantCountries(): Promise<string[]> {
    const response = await axios.get<string[]>(`${CONSTANTS}countries`);
    return response.data;
  }

  async getConstantLeagues(): Promise<League[]> {
    const response = await axios.get<League[]>(`${CONSTANTS}leagues`);

    return response.data;
  }

  async getDepthChart(depthChartId: number): Promise<DepthChart> {
    const repsonse = await axios.get<DepthChart>(
      `${DEPTH_CHARTS}${depthChartId}`
    );
    return repsonse.data;
  }

  async getDepthCharts(): Promise<DepthChart[] | null> {
    const repsonse = await axios.get<DepthChart[] | null>(`${DEPTH_CHARTS}`);
    return repsonse.data;
  }

  async getFolders(owner: string): Promise<Folder[] | null> {
    const response = await axios.get<Folder[] | null>(FOLDERS, {
      params: {
        owner: owner,
      },
    });
    return response.data;
  }

  async getList(
    mode: string,
    id: number,
    owner: string,
    filters?: GridFilterModel | null,
    sortModel?: GridSortModel | null
  ): Promise<PlayerList | null> {
    try {
      const snakedFilters = filters ? await this.obToSnake(filters) : null;
      const response = await axios.patch<PlayerList | null>(
        `${LISTS}${id}`,
        {
          filters: snakedFilters,
          sort_model: sortModel,
        },
        {
          params: {
            mode: mode,
            owner: owner,
          },
        }
      );
      return response.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getLists(owner: string): Promise<Folder[] | null> {
    const response = await axios.get<Folder[] | null>(LISTS, {
      params: {
        owner: owner,
      },
    });
    return response.data;
  }

  async getMatchReport(reportId: number): Promise<MatchReport> {
    try {
      const response = await axios.get<MatchReport>(
        `${MATCH_REPORTS}${reportId}`
      );

      return response.data;
    } catch (e) {
      console.error(e);
      return {} as MatchReport;
    }
  }

  async getMatchReports(): Promise<MatchReport[]> {
    try {
      const response = await axios.get<MatchReport[]>(MATCH_REPORTS);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getNotes(): Promise<Note[]> {
    try {
      const response = await axios.get<Note[]>(NOTES);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getNotifications() {
    const response = await axios.get<Notification[]>(NOTIFICATIONS);
    return response.data;
  }

  async getOperationsData(club: string): Promise<OperationsData> {
    const response = await axios.get<OperationsData>(OPERATIONS, {
      params: {
        team_name: club,
      },
    });

    return response.data;
  }

  async getPlayer(playerId: number): Promise<Player> {
    const response = await axios.get<Player>(`${PLAYERS}${playerId}`);

    // console.debug(response.data);
    return response.data;
  }

  async getPlayers(
    signal: AbortSignal | undefined,
    mode: string,
    pageNumber: number,
    pageSize: number,
    filters: GridFilterModel | undefined,
    sortModel: GridSortModel | undefined
  ): Promise<Page<Player[]>> {
    // console.debug(filters)
    const snakedFilters = await this.obToSnake(filters);
    const response = await axios.put<Page<Player[]>>(
      PLAYERS,
      {
        filters: snakedFilters,
        sort_model: sortModel,
      },
      {
        signal: signal,
        params: {
          mode: mode,
          page_number: pageNumber,
          page_size: pageSize,
        },
      }
    );

    // console.debug(response.data);
    return response.data;
  }

  async getPlayerAttributes(playerId: number): Promise<PlayerAttributes> {
    const params = {};

    const response = await axios.get<PlayerAttributes>(
      `${PLAYER_ATTRIBUTES}${playerId}`,
      {
        params: params,
      }
    );

    return response.data;
  }

  async getPlayerDatabaseMeta(): Promise<PlayerDatabaseMeta | null> {
    try {
      const response = await axios.get<PlayerDatabaseMeta | null>(
        "/players/database/meta",
        {}
      );
      return response.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getPlayerKPIs(playerId: number | undefined): Promise<PlayerKPIs> {
    const params = {};

    const response = await axios.get<PlayerKPIs>(`${PLAYERS}${playerId}/kpis`, {
      params: params,
    });

    return response.data;
  }

  async getPlayerNotes(playerId: number): Promise<Note[]> {
    try {
      const response = await axios.get<Note[]>(`${PLAYERS}${playerId}/notes`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getPlayerMatchReports(playerId: number): Promise<MatchReport[]> {
    const params = {};

    const response = await axios.get<MatchReport[]>(
      `${PLAYERS}${playerId}/match_reports`,
      {
        params: params,
      }
    );

    return response.data;
  }

  async getPlayerProfileQualities(
    profile: string | undefined
  ): Promise<ReportQuality[]> {
    try {
      const response = await axios.get<ReportQuality[]>(
        `${SCOUTING}match_reports/${profile}`
      );

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getPlayerRatings(playerId: number): Promise<PlayerRating[]> {
    const response = await axios.get<PlayerRating[]>(
      `${PLAYERS}${playerId}/ratings`
    );

    return response.data;
  }

  async getPlayersWeeklyStats(
    player: Player,
    metric: string
  ): Promise<WeeklyStat[]> {
    const response = await axios.get<WeeklyStat[]>(
      `/players/${player.id}/weekly/stats/${metric}`
    );

    return response.data;
  }

  async getScoutedPlayers(): Promise<ScoutedPlayer[] | null> {
    const response = await axios.get<ScoutedPlayer[] | null>(
      `${SCOUTED_PLAYERS}`
    );

    return response.data;
  }

  async getScoutedPlayerScoutingReports(
    scoutedPlayerId: number
  ): Promise<ScoutingReport[]> {
    const params = {};

    const response = await axios.get<ScoutingReport[]>(
      `${SCOUTED_PLAYERS}${scoutedPlayerId}/scouting_reports`,
      {
        params: params,
      }
    );

    return response.data;
  }

  async getScoutingReports(): Promise<[] | null> {
    const response = await axios.get<[] | null>("/scouting/reports");
    return response.data;
  }

  async getShares(
    email: string,
    objectId: number,
    objectType: string
  ): Promise<Share[] | null> {
    const response = await axios.get<Share[]>(SHARES, {
      params: {
        sharer_email: email,
        object_id: objectId,
        object_type: objectType,
      },
    });

    return response.data;
  }

  async getTeamStats(
    startDate: Date | null,
    endDate: Date | null,
    teamName: string
  ): Promise<Match[] | null> {
    const response = await axios.get<Match[]>(`/team/${teamName}/stats`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        team_name: teamName,
      },
    });

    return response.data;
  }

  async getTeamTable(team: string): Promise<TableEntries | null> {
    const response = await axios.get<TableEntries>(`team/${team}/table`, {
      params: {
        team_name: team,
      },
    });
    return response.data;
  }

  async getUsers(): Promise<User[] | null | undefined> {
    const response = await axios.get<User[]>(USERS);
    return response.data;
  }

  async searchPlayers(
    query: string,
    by: string,
    signal: AbortSignal | undefined
  ): Promise<Player[]> {
    const response = await axios.get<Player[]>(`${PLAYERS}search`, {
      params: {
        query_by_key: by,
        query_by_value: query,
      },
      signal,
    });
    return response.data;
  }

  async searchScoutedPlayers(
    query: string,
    by: string,
    pageSize: number,
    pageNumber: number
  ): Promise<Page<ScoutedPlayer[]>> {
    const response = await axios.get<Page<ScoutedPlayer[]>>(
      `${SCOUTED_PLAYERS}search`,
      {
        params: {
          query_by_key: by,
          query_by_value: query,
          page_number: pageNumber,
          page_size: pageSize,
        },
      }
    );
    return response.data;
  }

  async share(
    objectId: number,
    objectType: string,
    sharerEmail: string,
    shareWithEmail: string
  ) {
    try {
      const response = await axios.post<Share>(
        SHARES,
        {
          object_id: objectId,
          object_type: objectType,
          sharer_email: sharerEmail,
          share_with_email: shareWithEmail,
        },
        {}
      );
      return response.data;
    } catch (e: any) {
      console.error(e);
      return [];
    }
  }

  async unshare(
    shareId: number,
    sharerEmail: string,
    unshareWithEmail: string,
    objectId: number,
    objectType: string
  ) {
    try {
      const response = await axios.delete<Share>(`${SHARES}${shareId}`, {
        params: {
          object_id: objectId,
          object_type: objectType,
          sharer_email: sharerEmail,
          unshare_with_email: unshareWithEmail,
        },
      });
      return response.data;
    } catch (e: any) {
      console.error(e);
      return [];
    }
  }

  async updateDepthChart(depthChart: DepthChart) {
    const response = await axios.patch<DepthChart>(`${DEPTH_CHARTS}`, {
      ...depthChart,
    });

    return response.data;
  }

  async updateDepthChartPlayer(depthChartPlayer: DepthChartPlayer) {
    const response = await axios.patch<DepthChartPlayer>(
      `${DEPTH_CHARTS}players`,
      {
        ...depthChartPlayer,
      }
    );

    return response.data;
  }

  async updateDepthChartPositionList(
    depthChartPositionList: DepthChartPositionList
  ) {
    const response = await axios.patch<DepthChartPlayer>(
      `${DEPTH_CHARTS}position_list`,
      {
        ...depthChartPositionList,
      }
    );

    return response.data;
  }

  async updateListPlayers(
    action: string,
    id: number,
    owner: string,
    players: Player[]
  ): Promise<PlayerList> {
    const response = await axios.patch<PlayerList>(`${LISTS}${id}/${action}`, {
      owner: owner,
      players: this.normalizePlayers(players),
    });

    return response.data;
  }

  async updateListName(
    id: number,
    name: string,
    owner: string
  ): Promise<PlayerList> {
    const response = await axios.put<PlayerList>(`${LISTS}${id}`, {
      id: id,
      name: name,
      owner: owner,
    });
    return response.data;
  }

  async updateFolder(folder: Folder) {
    const response = await axios.put<Note>(`${FOLDERS}${folder.id}`, {
      name: folder.name,
      owner: folder.owner,
    });
    return response.data;
  }

  async updateListFolder(list: PlayerList) {
    const response = await axios.put<Note>(`${LISTS}${list.id}`, {
      name: list.name,
      owner: list.owner,
      folder_id: list.folder_id,
    });
    return response.data;
  }

  async updateNote(note: Note) {
    const response = await axios.patch<Note>(`${NOTES}${note.id}`, { ...note });
    return response.data;
  }

  async updatePlayer(player: PlayerUpdate) {
    const response = await axios.patch<PlayerUpdate>(`${PLAYERS}${player.id}`, {
      ...player,
    });
    return response.data;
  }

  async updatePlayerAttributes(
    playerAttributes: PlayerAttributes
  ): Promise<PlayerAttributes> {
    const response = await axios.patch<PlayerAttributes>(
      `${PLAYER_ATTRIBUTES}${playerAttributes.player_id}/`,
      {
        ...playerAttributes,
      }
    );
    return response.data;
  }

  async updateScoutedPlayer(scoutedPlayer: ScoutedPlayer) {
    const response = await axios.patch<Player>(
      `${SCOUTED_PLAYERS}${scoutedPlayer.id}`,
      { ...scoutedPlayer }
    );
    return response.data;
  }

  async updateScoutingReport(
    id: number,
    report: ScoutingReport
  ): Promise<ScoutingReport> {
    const response = await axios.put<ScoutingReport>(
      `${SCOUTING_REPORTS}${id}`,
      {
        ...report,
      }
    );
    return response.data;
  }
}

export { APIClient };
export default APIClient;
