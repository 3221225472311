import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { MatchReport, Player } from "../../../api/types";
import {
  SEARCH_PARAMS_PLAYER_ID,
  SEARCH_PARAMS_REPORT_ID,
} from "../../../constants";

import CircleIcon from "@mui/icons-material/Circle";
import CloseButton from "../../../components/buttons/CloseButton";
import { QualityGroupAccordion } from "../QualityGroupAccordion";
import { toTitle } from "../../../utils/stringManipulation";
import { useSearchParams } from "react-router-dom";
import { useThemeContext } from "../../../contexts/CustomThemeContext";

const qualityGroups = [
  {
    label: "Essential",
    group: "essential",
  },
  {
    label: "Psychological",
    group: "psychological",
  },
  {
    label: "Physical",
    group: "physical",
  },
  {
    label: "In Possession",
    group: "in_possession",
  },
  {
    label: "Out of Possession",
    group: "out_of_possession",
  },
  {
    label: "Effectiveness",
    group: "effectiveness",
  },
  {
    label: "Attacking Transition",
    group: "attacking_transition",
  },
  {
    label: "Aggressiveness",
    group: "aggressiveness",
  },
  {
    label: "Defensive Transition",
    group: "defensive_transition",
  },
  {
    label: "Set Pieces",
    group: "set_pieces",
  },
  {
    label: "Game Impact",
    group: "game_impact",
  },
];

export interface QueryScoutingReportDialogProps {
  player: Player | undefined;
  open: boolean;
  report: MatchReport | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

export default function QueryScoutingReportDialog(
  props: QueryScoutingReportDialogProps
) {
  // CONTEXT
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const { theme } = useThemeContext();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // STATE
  const [expanded, setExpanded] = useState<string | false>(false);

  // VARIABLES
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // FUNCTIONS
  const handleAccordionChange =
    (group: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? group : false);
    };

  const onPlayerNameClick = () => {
    if (props.report) {
      props.onClose();
      searchParams.delete(SEARCH_PARAMS_REPORT_ID);
      searchParams.set(SEARCH_PARAMS_PLAYER_ID, String(props.report.player_id));
      setSearchParams(searchParams);
    }
  };

  // MEMO
  const QualityGroups = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return qualityGroups.map((qg) => {
      if (props.report) {
        const filteredRatings =
          props?.report?.ratings?.filter((r) => r.quality.group === qg.group) ||
          [];

        if (
          (props.report.profile === "goalkeeper" &&
            qg.group !== "attacking_transition" &&
            qg.group !== "defensive_transition") ||
          (props.report.profile !== "goalkeeper" &&
            qg.group !== "effectiveness" &&
            qg.group !== "aggressiveness")
        ) {
          return (
            <QualityGroupAccordion
              key={qg.label}
              label={qg.label}
              group={qg.group}
              profile={props.report.profile}
              expanded={expanded}
              handleAccordionChange={handleAccordionChange}
              ratings={filteredRatings}
              readOnly={true}
            />
          );
        }
      }
    });
  }, [props.report, expanded]);

  return (
    <Box key={"query-match-report-dialog-outer-box"}>
      <Dialog
        open={props.open}
        fullScreen={fullScreen}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: fullScreen ? 0 : 5,
            width: "100%",
          },
        }}
        disableEscapeKeyDown
        // This is necessary to remedy a rendering issue on close of the modal. Modal opacity is set to 0
        // but z-index is still more than reports page and so the only clickable content is the invisible dialog.
        // So need to set the zIndex manually for now
        sx={{ zIndex: props.open ? theme.zIndex.modal : -1 }}
      >
        <DialogTitle
          key={"mutate-match-report-dialog-title"}
          sx={{ textAlign: "center", color: theme.palette.primary.main }}
        >
          <Box textAlign={"center"}>
            {(props?.report?.player_name && (
              <Link
                onClick={() => onPlayerNameClick()}
                sx={{ cursor: "pointer" }}
              >
                <Typography variant={"h5"} fontWeight={500}>
                  {props.report.player_name}
                </Typography>
              </Link>
            )) || <Typography fontWeight={500}>MATCH REPORT</Typography>}
          </Box>
        </DialogTitle>

        <DialogContent key={"mutate-match-report-dialog-content"}>
          <CloseButton
            position="absolute"
            top={5}
            left={5}
            onClick={() => {
              props.onClose();
              setExpanded(false);
              searchParams.delete(SEARCH_PARAMS_REPORT_ID);
              setSearchParams(searchParams);
            }}
          />

          <Stack key={"mutate-match-report-dialog-stack"} spacing={2}>
            {props.report && (
              <Stack spacing={2} sx={{ color: theme.palette.primary.main }}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography>{`Stance: ${props.report.stance}`}</Typography>
                  <CircleIcon
                    sx={{
                      height: 16,
                      color:
                        props.report.stance === "Pass"
                          ? "red"
                          : props.report.stance === "Monitor"
                          ? "yellow"
                          : "green",
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">
                    {new Date(props.report.date).toLocaleString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </Typography>
                  <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                    {props.report.scout}
                  </Typography>
                </Box>
                <Typography variant="body2" textAlign={"center"}>
                  Position Played in Game: {props.report.position.toUpperCase()}
                </Typography>
                {(!isScreenSmall && (
                  <Box display="flex" justifyContent={"center"}>
                    <Typography
                      variant="caption"
                      ml={0.5}
                      mr={0.5}
                    >{`(${props.report.player_team_formation})`}</Typography>
                    <Typography
                      ml={0.5}
                      mr={0.5}
                      sx={{ textDecoration: "underline" }}
                    >
                      {props.report.player_team}
                    </Typography>
                    <Typography ml={0.5} mr={0.5} fontWeight={500}>
                      {props.report.player_team_goals}
                    </Typography>
                    <Typography ml={0.5} mr={0.5} fontWeight={500}>
                      -
                    </Typography>
                    <Typography ml={0.5} mr={0.5} fontWeight={500}>
                      {props.report.opposition_goals}
                    </Typography>
                    <Typography ml={0.5} mr={0.5}>
                      {props.report.opposition}
                    </Typography>
                    <Typography ml={0.5} mr={0.5} variant="caption">
                      {`(${props.report.opposition_formation})`}
                    </Typography>
                  </Box>
                )) || (
                  <>
                    <Box display="flex" justifyContent={"center"}>
                      <Typography
                        variant="caption"
                        ml={0.5}
                        mr={0.5}
                      >{`(${props.report.player_team_formation})`}</Typography>
                      <Typography
                        ml={0.5}
                        mr={0.5}
                        sx={{ textDecoration: "underline" }}
                      >
                        {props.report.player_team}
                      </Typography>
                      <Typography ml={0.5} mr={0.5} fontWeight={500}>
                        {props.report.player_team_goals}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent={"center"}>
                      <Typography ml={0.5} mr={0.5} variant="caption">
                        {`(${props.report.opposition_formation})`}
                      </Typography>
                      <Typography ml={0.5} mr={0.5}>
                        {props.report.opposition}
                      </Typography>
                      <Typography ml={0.5} mr={0.5} fontWeight={500}>
                        {props.report.opposition_goals}
                      </Typography>
                    </Box>
                  </>
                )}
                <Box mt={2} display="flex" justifyContent="center">
                  <Typography>{`Player Profile: `}</Typography>
                  <Typography
                    fontWeight={500}
                    sx={{ textDecoration: "underline" }}
                  >
                    {toTitle(props.report.profile)}
                  </Typography>
                </Box>
                {QualityGroups}
                <Typography textAlign="center">{props.report.notes}</Typography>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
