import { ANALYTICS_ROLE, SUPERUSER } from "./constants";
import {
  COACHES_CORNER_ROUTE,
  IDP_ROUTE,
  LOGIN_CALLBACK_ROUTE,
  MATCH_REPORTS_ROUTE,
  NOTES_ROUTE,
  OPERATIONS_ROOT_ROUTE,
  ROOT_ROUTE,
  SCOUTING_PLAYERS_ROUTE,
  SCOUTING_PLAYER_RATINGS_ROUTE,
  SCOUTING_REPORTS_ROUTE,
  SCOUTING_ROOT_ROUTE,
  SKILLCORNER_ROUTE,
} from "./routes";
import { Route, Routes, useNavigate } from "react-router-dom";

import DepthChart from "./pages/scouting/depth_charts";
import { HOTKEYS } from "./nav/HotKeys";
import { LoginCallback } from "@okta/okta-react";
import SecureRoute from "./components/SecureRoute";
import { lazy } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useUserContext } from "./contexts/UserContext";
import SkillCornerPage from "./pages/skillcorner";

const CoachesCornerPage = lazy(() => import("./pages/coaches_corner"));
const IDPPage = lazy(() => import("./pages/idp"));
const Operations = lazy(() => import("./pages/operations"));
// const Scouting = lazy(() => import("./pages/scouting/"));
const Players = lazy(() => import("./pages/players"));
const PlayerRatings = lazy(() => import("./pages/players/ratings"));
const Notes = lazy(() => import("./pages/notes"));
const MatchReports = lazy(() => import("./pages/match_reports"));
const ScoutingReports = lazy(() => import("./pages/scouting_reports"));

export default function AppRoutes() {
  const { HomePage, roles } = useUserContext();
  const navigate = useNavigate();

  useHotkeys(HOTKEYS["match_reports"].key, (event) => {
    navigate(HOTKEYS["match_reports"].route);
    event.preventDefault();
  });
  useHotkeys(HOTKEYS["notes"].key, (event) => {
    navigate(HOTKEYS["notes"].route);
    event.preventDefault();
  });
  useHotkeys(HOTKEYS["players"].key, (event) => {
    navigate(HOTKEYS["players"].route);
    event.preventDefault();
  });
  useHotkeys(HOTKEYS["scouting_reports"].key, (event) => {
    navigate(HOTKEYS["scouting_reports"].route);
    event.preventDefault();
  });

  //   console.debug(roles);
  //   console.debug(permissions);

  return (
    <Routes>
      <Route path={LOGIN_CALLBACK_ROUTE} element={<LoginCallback />} />
      <Route path={ROOT_ROUTE} element={<SecureRoute />}>
        <Route path="" element={HomePage} />
      </Route>
      <Route path={COACHES_CORNER_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<CoachesCornerPage />} />
      </Route>
      <Route path={IDP_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<IDPPage />} />
      </Route>
      <Route path={SKILLCORNER_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<SkillCornerPage />} />
      </Route>
      <Route path={OPERATIONS_ROOT_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Operations />} />
      </Route>

      {(roles.includes(SUPERUSER) || roles.includes(ANALYTICS_ROLE)) && (
        <Route path={MATCH_REPORTS_ROUTE} element={<SecureRoute />}>
          <Route path="" element={<MatchReports />} />
        </Route>
      )}

      {(roles.includes(SUPERUSER) || roles.includes(ANALYTICS_ROLE)) && (
        <Route path={NOTES_ROUTE} element={<SecureRoute />}>
          <Route path="" element={<Notes />} />
        </Route>
      )}

      {(roles.includes(SUPERUSER) || roles.includes(ANALYTICS_ROLE)) && (
        <Route path={SCOUTING_PLAYERS_ROUTE} element={<SecureRoute />}>
          <Route path="" element={<Players />} />
        </Route>
      )}

      {(roles.includes(SUPERUSER) || roles.includes(ANALYTICS_ROLE)) && (
        <Route path={SCOUTING_PLAYER_RATINGS_ROUTE} element={<SecureRoute />}>
          <Route path="" element={<PlayerRatings />} />
        </Route>
      )}

      {(roles.includes(SUPERUSER) || roles.includes(ANALYTICS_ROLE)) && (
        <Route path={SCOUTING_REPORTS_ROUTE} element={<SecureRoute />}>
          <Route path="" element={<ScoutingReports />} />
        </Route>
      )}

      {(roles.includes(SUPERUSER) || roles.includes(ANALYTICS_ROLE)) && (
        <Route path={SCOUTING_ROOT_ROUTE} element={<SecureRoute />}>
          <Route path="" element={<Players />} />
          <Route path="players" element={<Players />} />
          <Route path="depth" element={<DepthChart />} />
          <Route path="notes" element={<Notes />} />
          <Route path="match_reports" element={<MatchReports />} />
        </Route>
      )}
    </Routes>
  );
}
