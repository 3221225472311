import TableauDashboard from "../../components/TableauDashboard";

const WORKBOOK_URL =
  "https://prod-useast-b.online.tableau.com/t/waldenanalytics/views/SkillCornerxChicagoFC-CombinedTemplate/PC-Summary";

const SkillCornerPage = () => {
  return <TableauDashboard url={WORKBOOK_URL} />;
};

export default SkillCornerPage;
