import {
  Autocomplete,
  Box,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  MatchReport,
  Player,
  PlayerProfile,
  Position,
  ReportQualityRating,
} from "../../../api/types";
import { playerProfiles, positions } from "../../../constants";

import Button from "@mui/material/Button";
import CircleIcon from "@mui/icons-material/Circle";
import CloseButton from "../../../components/buttons/CloseButton";
import { DatePicker } from "@mui/x-date-pickers";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { QualityGroupAccordion } from "../QualityGroupAccordion";
import { useCreateMatchReportMutation } from "../../../api/mutations";
import { useOktaAuthContext } from "../../../contexts/OktaAuthContext";
import { usePlayerProfileQualities } from "../../../api/queries";

const qualityGroups = [
  {
    label: "Essential",
    group: "essential",
  },
  {
    label: "Psychological",
    group: "psychological",
  },
  {
    label: "Physical",
    group: "physical",
  },
  {
    label: "In Possession",
    group: "in_possession",
  },
  {
    label: "Out of Possession",
    group: "out_of_possession",
  },
  {
    label: "Effectiveness",
    group: "effectiveness",
  },
  {
    label: "Attacking Transition",
    group: "attacking_transition",
  },
  {
    label: "Aggressiveness",
    group: "aggressiveness",
  },
  {
    label: "Defensive Transition",
    group: "defensive_transition",
  },
  {
    label: "Set Pieces",
    group: "set_pieces",
  },
  {
    label: "Game Impact",
    group: "game_impact",
  },
];

const formations = [
  "4-4-2",
  "4-3-3",
  "4-2-3-1",
  "3-5-2",
  "3-4-3",
  "5-3-2",
  "4-5-1",
  "4-1-4-1",
  "4-4-1-1",
  "4-3-2-1",
  "4-4-2 (Diamond)",
  "4-2-2-2",
];

export interface MutateScoutingReportDialogProps {
  player: Player | undefined;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

export default function MutateScoutingReportDialog(
  props: MutateScoutingReportDialogProps
) {
  // CONTEXT
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { name } = useOktaAuthContext();

  // STATE
  const [date, setDate] = useState<Date | null>();
  const [position, setPosition] = useState<Position | null>();
  const [playerTeam, setPlayerTeam] = useState<string>();
  const [playerTeamGoals, setPlayerTeamGoals] = useState<number | string>();
  const [playerTeamFormation, setPlayerTeamFormation] = useState<
    string | null
  >();
  const [oppositionTeam, setOppositionTeam] = useState<string>();
  const [oppositionGoals, setOppositionGoals] = useState<number | string>();
  const [oppositionFormation, setOppositionFormation] = useState<
    string | null
  >();
  const [notes, setNotes] = useState<string>();
  const [stance, setStance] = useState<string>();

  // ratings
  const [profile, setProfile] = useState<PlayerProfile | null>();
  const [ratings, setRatings] = useState<any>();
  const [expanded, setExpanded] = useState<string | false>(false);

  // QUERIES
  const profileQualities = usePlayerProfileQualities(profile?.profile);

  // MUTATIONS
  const createMatchReport = useCreateMatchReportMutation();

  // FUNCTIONS
  const handleAccordionChange =
    (group: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? group : false);
    };

  const updateRating = useCallback(
    (group: string, rating: ReportQualityRating) => {
      const updatedQualityGroup = structuredClone(ratings[group]);

      const ind = updatedQualityGroup.findIndex(
        (r: ReportQualityRating) => r.quality.key === rating.quality.key
      );

      updatedQualityGroup[ind].rating = rating.rating;
      updatedQualityGroup[ind].note = rating.note;

      setRatings((prevData: any) => ({
        ...prevData,
        [group]: updatedQualityGroup,
      }));
    },
    [ratings]
  );

  const handleSubmit = () => {
    // flatten qualities
    const flattenedRatings = Object.entries(ratings)
      .map((g) => g[1] as ReportQualityRating[])
      .flat();

    const payload = {
      player_id: props?.player?.id,
      date,
      notes: notes,
      position: position?.key,
      profile: profile?.profile,
      player_team: playerTeam,
      player_team_goals: playerTeamGoals,
      player_team_formation: playerTeamFormation,
      opposition: oppositionTeam,
      opposition_goals: oppositionGoals,
      opposition_formation: oppositionFormation,
      ratings: flattenedRatings,
      scout: name,
      stance: stance,
    };

    console.debug(payload);
    createMatchReport.mutate(payload as unknown as MatchReport);
    props.setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setDate(undefined);
    setPosition(undefined);
    setPlayerTeam(undefined);
    setPlayerTeamGoals(undefined);
    setPlayerTeamFormation(undefined);
    setOppositionTeam(undefined);
    setOppositionGoals(undefined);
    setOppositionFormation(undefined);
    setProfile(undefined);
    setRatings(undefined);
    setNotes(undefined);
    setStance(undefined);

    setExpanded(false);
  };

  useEffect(() => {
    if (profileQualities.data && profileQualities.data.length > 0) {
      const groups = profileQualities.data.reduce((gs, pq) => {
        (gs[pq.group] = gs[pq.group] || []).push({
          quality: pq,
          quality_id: pq.id,
        } as ReportQualityRating);

        return gs;
      }, {} as { [key: string]: ReportQualityRating[] });

      setRatings(groups);
    } else {
      setRatings(null);
    }
  }, [profileQualities.data]);

  // MEMO
  const QualityGroups = useMemo(() => {
    return (
      profile &&
      ratings &&
      !profileQualities.isFetching &&
      // eslint-disable-next-line array-callback-return
      qualityGroups.map((qg) => {
        if (
          (profile.profile === "goalkeeper" &&
            qg.group !== "attacking_transition" &&
            qg.group !== "defensive_transition") ||
          (profile.profile !== "goalkeeper" &&
            qg.group !== "effectiveness" &&
            qg.group !== "aggressiveness")
        ) {
          return (
            <QualityGroupAccordion
              key={qg.label}
              label={qg.label}
              group={qg.group}
              profile={profile.profile}
              expanded={expanded}
              handleAccordionChange={handleAccordionChange}
              ratings={ratings[qg.group]}
              updateRating={updateRating}
              readOnly={false}
            />
          );
        }
      })
    );
  }, [profile, expanded, ratings, updateRating, profileQualities.isFetching]);

  return (
    <Box key={"mutate-scouting-report-dialog-outer-box"}>
      <Dialog
        open={props.open}
        onClose={(event: object, reason: string) => {
          if (reason === "escapeKeyDown") {
            props.onClose();
            resetForm();
          }
        }}
        key={"Scouting Report"}
        fullScreen={isScreenSmall}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: isScreenSmall ? 0 : 5,
            width: "100%",
          },
        }}
        disableEscapeKeyDown
      >
        <DialogTitle
          key={"mutate-scouting-report-dialog-title"}
          sx={{ textAlign: "center" }}
        >
          MATCH REPORT
        </DialogTitle>

        <DialogContent key={"mutate-scouting-report-dialog-content"}>
          <CloseButton
            position="absolute"
            top={5}
            left={5}
            onClick={() => {
              props.onClose();
              resetForm();
            }}
          />

          <Stack key={"mutate-scouting-report-dialog-stack"} spacing={2}>
            {props?.player?.id && (
              <Stack spacing={2}>
                <DatePicker
                  name="report-dialog-date"
                  label="Match Date"
                  value={date}
                  onChange={(value: Date | null) => {
                    setDate(value);
                  }}
                />

                <Autocomplete
                  id="report-dialog-position"
                  options={positions}
                  getOptionLabel={(p) => `${p.label} (${p.position})`}
                  renderInput={(params) => (
                    <TextField {...params} label="Position Played in Game" />
                  )}
                  defaultValue={null}
                  value={position || null}
                  onChange={(event: any, p: Position | null) => setPosition(p)}
                />
                <Stack
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  sx={{ color: theme.palette.primary.main }}
                >
                  <Stack spacing={2}>
                    <Divider>{"PLAYER'S TEAM"}</Divider>
                    <Stack spacing={1}>
                      <TextField
                        label="Team"
                        value={playerTeam}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setPlayerTeam(event.target.value)}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <TextField
                        label="Goals"
                        value={playerTeamGoals}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setPlayerTeamGoals(event.target.value);
                        }}
                        slotProps={{
                          input: {
                            type: "number",
                            slotProps: {
                              input: {
                                min: 0,
                              },
                            },
                          },
                        }}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <Autocomplete
                        id="report-dialog-player-team-formation"
                        options={formations}
                        renderInput={(params) => (
                          <TextField {...params} label="Formation" />
                        )}
                        defaultValue={null}
                        value={playerTeamFormation || null}
                        onChange={(event: any, f: string | null) =>
                          setPlayerTeamFormation(f)
                        }
                      />
                    </Stack>
                  </Stack>
                  <Stack spacing={2}>
                    <Divider>OPPOSITION</Divider>
                    <Stack spacing={1}>
                      <TextField
                        label="Team"
                        value={oppositionTeam}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setOppositionTeam(event.target.value)}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <TextField
                        label="Goals"
                        value={oppositionGoals}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setOppositionGoals(event.target.value)}
                        slotProps={{
                          input: {
                            type: "number",
                            slotProps: {
                              input: {
                                min: 0,
                              },
                            },
                          },
                        }}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <Autocomplete
                        id="report-dialog-opposition-formation"
                        options={formations}
                        renderInput={(params) => (
                          <TextField {...params} label="Formation" />
                        )}
                        defaultValue={null}
                        value={oppositionFormation || null}
                        onChange={(event: any, f: string | null) =>
                          setOppositionFormation(f)
                        }
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Autocomplete
                  id="report-dialog-player-profile"
                  options={playerProfiles}
                  getOptionLabel={(p) => `${p.label} (${p.position})`}
                  renderInput={(params) => (
                    <TextField {...params} label="Player Profile" />
                  )}
                  value={profile}
                  onChange={(event: any, p: PlayerProfile | null) =>
                    setProfile(p)
                  }
                  sx={{ pt: 4 }}
                />
                {profile && (
                  <>
                    <Divider>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        RATINGS
                      </Typography>
                    </Divider>
                    {QualityGroups}
                  </>
                )}
                <>
                  <Divider>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      NOTES
                    </Typography>
                  </Divider>
                  <TextField
                    value={notes}
                    onChange={(event) => {
                      setNotes(event.target.value);
                    }}
                    label="Notes"
                    multiline
                    minRows={2}
                  />
                </>
                <>
                  <Divider>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      STANCE
                    </Typography>
                  </Divider>
                  <Box display={"flex"} justifyContent={"center"}>
                    <Select
                      sx={{ width: "200px" }}
                      // fullWidth
                      value={stance || ""}
                      onChange={(e: SelectChangeEvent) => {
                        setStance(e.target.value);
                      }}
                    >
                      {["Pass", "Monitor", "Sign"].map((s) => {
                        return (
                          <MenuItem key={s} value={s}>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography>{s}</Typography>
                              <CircleIcon
                                sx={{
                                  height: 16,
                                  color:
                                    s === "Pass"
                                      ? "red"
                                      : s === "Monitor"
                                      ? "yellow"
                                      : "green",
                                }}
                              />
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </>
              </Stack>
            )}
            <Button
              variant="outlined"
              key="mutate-scouting-report-dialog-button"
              onClick={handleSubmit}
              disabled={
                !date ||
                !position ||
                !playerTeam ||
                !playerTeamGoals ||
                !playerTeamFormation ||
                !oppositionTeam ||
                !oppositionGoals ||
                !oppositionFormation ||
                !profile ||
                !stance
              }
            >
              SUBMIT
            </Button>
          </Stack>
        </DialogContent>

        {/* <DialogActions
          key={"mutate-scouting-report-dialog-actions"}
          sx={{ display: "flex", justifyContent: "center" }}
        ></DialogActions> */}
      </Dialog>
    </Box>
  );
}
